import React from "react";
import Layout from "../components/layout";
import { Link, graphql, useStaticQuery } from "gatsby";
import Head from "../components/head";

const BlogPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
					author
					description
					image
				}
			}
		}
	`);

	const schema = [
		{
			knowsAbout: [
				{
					"@context": "http://schema.org",
					"@type": "Thing",
					name: "OKR",
					description:
						"Objectives and key results (OKR alternatively OKRs) is a goal-setting framework used by individuals teams and organizations to define measurable goals and track their outcomes. The development of OKR is generally attributed to Andrew Grove who introduced the approach to Intel in the 1970s",
					SameAs: [
						"http://en.wikipedia.org/wiki/OKR",
						"https://www.wikidata.org/wiki/Q7072610",
					],
				},
			],
		},
		{
			about: [
				{
					"@type": "Thing",
					name: "OKR",
					description:
						"Objectives and key results (OKR alternatively OKRs) is a goal-setting framework used by individuals teams and organizations to define measurable goals and track their outcomes. The development of OKR is generally attributed to Andrew Grove who introduced the approach to Intel in the 1970s",
					SameAs: [
						"http://en.wikipedia.org/wiki/OKR",
						"https://www.wikidata.org/wiki/Q7072610",
					],
				},
			],
			mentions: [
				{
					"@type": "Thing",
					name: "OKR",
					description:
						"Objectives and key results (OKR alternatively OKRs) is a goal-setting framework used by individuals teams and organizations to define measurable goals and track their outcomes. The development of OKR is generally attributed to Andrew Grove who introduced the approach to Intel in the 1970s",
					SameAs: [
						"http://en.wikipedia.org/wiki/OKR",
						"https://www.wikidata.org/wiki/Q7072610",
					],
				},
			],
		},
		{
			knowsAbout: [
				{
					"@context": "http://schema.org",
					"@type": "Thing",
					name: "OKR",
					description:
						"Objectives and key results (OKR alternatively OKRs) is a goal-setting framework used by individuals teams and organizations to define measurable goals and track their outcomes. The development of OKR is generally attributed to Andrew Grove who introduced the approach to Intel in the 1970s",
					SameAs: [
						"http://en.wikipedia.org/wiki/OKR",
						"https://www.wikidata.org/wiki/Q7072610",
					],
				},
			],
		},
	];

	return (
		<Layout>
			<Head
				title={`${data.site.siteMetadata.author} | ${data.site.siteMetadata.description}`}
				schemaMarkup={schema}
			/>
			<main className="mx-auto my-10 max-w-4xl sm:my-12 md:my-16 lg:my-20 xl:my-28">
				<div className="sm:text-center">
					<h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl md:text-6xl">
						<span className="block xl:inline">
							Learn the framework that helps you set laser-focused goals and
							execute on them
						</span>{" "}
					</h1>
					<p className="mt-3 text-base text-slate-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-auto">
						Dive into our articles covering everything you need to know to get
						started using OKRs.
					</p>
					<div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
						<div className="rounded-md shadow">
							<Link
								to={"/okr-basics/"}
								className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg">
								Get started
							</Link>
						</div>
						<div className="mt-3 sm:mt-0 sm:ml-3">
							<Link
								to={"/okr-examples/"}
								className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 md:py-4 md:px-10 md:text-lg">
								See +100 examples
							</Link>
						</div>
					</div>
				</div>
			</main>
		</Layout>
	);
};

export default BlogPage;
